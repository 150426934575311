import React from 'react'
import GetFooter from '../Hooks/GetFooter'
import GetContact from '../Hooks/GetContact'

function Contacto() {
  return (
    <div className='flex flex-col'>
      <GetContact />
      <GetFooter />
    </div>
  )
}

export default Contacto
