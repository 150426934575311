import React, { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../Context/SessionContext'
import { LanguageContext } from '../Context/LanguageContext'
import { CurrencyContext } from '../Context/CurrencyContext'
import UICupons from './UICupons'
import UIStoreforntCartContentsCell from './UIStoreforntCartContentsCell'

export default function UIStorefrontCartContents(props) {
  const { session, setSession } = useContext(SessionContext)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const language = useContext(LanguageContext)
  const textCar = language.text.car
  const coupon = JSON.parse(localStorage.getItem('coupon'))
  const [freeshipping, setFreeshipping] = useState(false) //FREESHIPPING

  useEffect(() => {
    const shipping = JSON.parse(localStorage.getItem('coupon'))
    if (shipping !== null) {
      if (shipping?.benefit === 'freeshipping') {
        setFreeshipping(true)
      } else {
        setFreeshipping(false)
      }
    } else {
      setFreeshipping(false)
    }
  }, [localStorage])

  const decrease = (id) => {
    const tempCart = session.map((cart) => {
      if (cart.id1 === id) {
        let newAmount = cart.amount - 1
        if (newAmount < 1) {
          newAmount = 1
        }
        return {
          ...cart,
          amount: newAmount,
        }
      }
      return cart
    })

    setSession(tempCart)
  }

  const increase = (id, id2) => {
    console.log('ENTRANDO A INCREMENTAR')
    const tempCart = session.map((cart) => {
      const tempTODO = session.filter((i) => i.id2 === id2)
      if (tempTODO.length > 1) {
        let amountExtrasTodo = 0

        tempTODO.forEach((element) => {
          amountExtrasTodo += element.amount
        })

        if (cart.id1 === id) {
          console.log('entre???')
          if (cart.max === '') {
            let newAmount = cart.amount + 1

            return {
              ...cart,
              amount: newAmount,
            }
          }

          if (cart.max <= amountExtrasTodo) {
            console.log('entre111')

            console.log('TODO', amountExtrasTodo)
            console.log('CARTAMOUYNT', cart.amount)

            return { ...cart }
          } else {
            let newAmount = cart.amount + 1
            if (newAmount > cart.max) {
              newAmount = cart.max
            }
            return {
              ...cart,
              amount: newAmount,
            }
          }
        }
      } else if (cart.id1 === id) {
        console.log('ENTRANDO A ELSE IF')
        console.log('CART.MAX', cart.max)
        console.log('CART.amount', cart.amount)

        let newAmount = cart.amount + 1
        if (cart.max === '') {
          console.log('max vacio')
          return {
            ...cart,
            amount: newAmount,
          }
        }

        if (newAmount > cart.max) {
          console.log('PRIMER IF DE ELSE')
          newAmount = cart.max
        }
        return {
          ...cart,
          amount: newAmount,
        }
      }
      return cart
    })

    setSession(tempCart)
  }

  const maprr = session.map((x) => {
    const amount = x.amount || 1
    return x.precio * amount
  })

  let deleteProduct = (e) => {
    console.log('miSession', session)
    let copyProducts = [...session]
    copyProducts.splice(e, 1)
    localStorage.setItem('session', JSON.stringify(copyProducts))
    setSession(copyProducts)
  }

  let sumTotal = () => {
    const maprr = session.map((x) => {
      const amount = x.amount || 1
      return x.precio * amount
    })
    return Number(maprr.reduce((a, b) => Number(a) + Number(b), 0))
  }

  return (
    <div className='pt-2 mb-2 border border-gray-300 rounded'>
      {session.length > 0 ? (
        <div className=''>
          {session.map((cart, i) => {
            return (
              <UIStoreforntCartContentsCell
                theme={props.theme}
                deleteProduct={(e) => deleteProduct(e)}
                id1={cart.id1}
                id2={cart.id2}
                decrease={decrease}
                increase={increase}
                key={i}
                index={i}
                imagen={cart.imagenproducto}
                precio={cart.precio}
                nombre={cart.nombreproducto}
                variation={cart.variation}
                extras={cart.extras}
                amount={cart.amount}
                max={cart.max}
                cart={cart}
              />
            )
          })}
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center pt-2 text-sm text-gray-500'>
          {' '}
          {textCar.empyCar}
        </div>
      )}

      <div className='p-4'>
        <UICupons />
      </div>

      <div className='flex flex-row items-center justify-between p-4 mt-4 border-t border-gray-300'>
        <div className='text-lg font-bold tracking-tighter '>
          {textCar.total}
        </div>

        <div className='flex flex-row items-center gap-1 text-2xl tracking-tighter'>
          <div className='flex items-center gap-1 font-semibold'>
            {coupon !== null ? (
              <React.Fragment>
                <div className='text-right line-through opacity-40'>
                  <div className='text-xs'>
                    {currency}
                    {Number(
                      session
                        .map((x) => x.precio)
                        .reduce((a, b) => Number(a) + Number(b), 0)
                    ).toFixed(2)}
                  </div>
                  <div className='text-xxs'>cupón aplicado</div>
                </div>
                {/* <div className="text-sm">S/</div>  */}

                {freeshipping ? sumTotal : localStorage.getItem('newTotal')}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='text-sm'>{currency}</div>
                {sumTotal()}
              </React.Fragment>
            )}
          </div>
        </div>

        {/* <div className='flex flex-row gap-1 text-2xl tracking-tighter'>
          <div className='text-sm'>{currency}</div>
          <div className='font-semibold'>
            {Number(maprr.reduce((a, b) => Number(a) + Number(b), 0)).toFixed(
              2
            )}
          </div>
        </div> */}
      </div>
    </div>
  )
}
