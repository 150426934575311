import { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../Context/StoreContext'
import { CleanCupon, GetCuponsByDomainAPI } from '../Utils/GetCuponsByDomain'
import { SessionContext } from '../Context/SessionContext'
import React from 'react'

export default function UICupons() {
  const { session, setSession } = useContext(SessionContext)
  const cuponActivo = JSON.parse(localStorage.getItem('coupon'))
  const total = Number(
    session.map((x) => x.precio).reduce((a, b) => Number(a) + Number(b), 0)
  ).toFixed(2)

  const { store } = useContext(StoreContext)
  const [cupons, setCupons] = useState('')
  const [valid, setValid] = useState(false)
  const [discount, setDiscount] = useState(null)
  const [newTotal, setNewTotal] = useState(null)

  const [collected, setCollected] = useState(false)

  useEffect(() => {
    GetCuponsByDomainAPI(store.id).then((data) => {
      // console.log(data);
      if (data) {
        setCupons(data)
      }
    })
  }, [])

  const changeHandler = (e) => {
    const intent = cupons.find((cupon) => cupon.name === e.target.value)
    if (intent) {
      setValid(true)
      localStorage.setItem('freeshipping', false)
      setDiscount(intent)
    } else {
      setValid(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    console.log(discount)
    if (discount.benefit === 'percentage') {
      setNewTotal((total - (total * discount.value) / 100).toFixed(2))
      localStorage.setItem('coupon', JSON.stringify(discount))
      localStorage.setItem(
        'newTotal',
        (total - (total * discount.value) / 100).toFixed(2)
      )
      setCollected(true)
    }
    if (discount.benefit === 'discount') {
      setNewTotal((total - discount.value).toFixed(2))
      localStorage.setItem('coupon', JSON.stringify(discount))
      localStorage.setItem('newTotal', (total - discount.value).toFixed(2))
      setCollected(true)
    }
    if (discount.benefit === 'freeshipping') {
      setNewTotal((total - discount.value).toFixed(2))
      localStorage.setItem('coupon', JSON.stringify(discount))
      localStorage.setItem('freeshipping', true)
      setCollected(true)
    }
    window.location.reload(false) //BUGFIX
  }

  return (
    <React.Fragment>
      {/* {console.log(store)} */}
      {cuponActivo?.name === undefined ? (
        cupons !== '' ? (
          <div className='py-4'>
            <div className='text-xxs'>tienes un cupon?</div>
            <form className='flex gap-1 my-2' onSubmit={submitHandler}>
              <div className='flex w-full text-xs border border-slate-200'>
                <input
                  className='flex w-full p-3'
                  type='text'
                  placeholder='ingresa tu cupon'
                  onChange={(e) => changeHandler(e)}
                  onSubmit={(e) => submitHandler(e)}
                />
              </div>

              <button
                type='submit'
                disabled={!valid}
                className={`text-xs flex px-2 tracking-tight font-semibold items-center ${
                  !valid
                    ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                    : 'bg-yellow-400 rounded-md'
                }`}
              >
                Aplicar
              </button>
            </form>

            <div
              className={` text-right text-xxs ${
                collected
                  ? 'text-green-500'
                  : valid
                  ? 'text-blue-500'
                  : 'text-gray-400'
              } `}
            >
              {collected
                ? 'Cupon aplicado'
                : valid
                ? 'CUPON VALIDO'
                : 'CUPON INVALIDO'}
            </div>
            {!collected
              ? valid && (
                  <div className='flex flex-col gap-2 p-4 my-2 rounded-lg bg-slate-100 text-xxs'>
                    <div className='flex justify-between'>
                      Tipo de descuento:{' '}
                      <span className='font-bold'>{discount.benefit}</span>
                    </div>
                    <div className='flex justify-between'>
                      {discount.benefit !== 'freeshipping' &&
                        'Valor del descuento:'}
                      <span className='font-bold'>
                        {discount.benefit === 'percentage'
                          ? discount.value.toFixed(2) + '%'
                          : discount.benefit === 'freeshipping'
                          ? ''
                          : 'S/. ' + discount.value.toFixed(2)}
                      </span>
                    </div>
                  </div>
                )
              : ' '}
          </div>
        ) : (
          ''
        )
      ) : (
        <div className='flex items-center justify-between p-4 bg-green-200 rounded-lg text-xxs'>
          <div className='opacity-50'>Cupón activo</div>
          <div className='text-xs font-bold text-green-700 uppercase'>
            {cuponActivo.name}
            {/* {console.log(cuponActivo)} */}
          </div>
          <div
            className='cursor-pointer'
            onClick={() => {
              CleanCupon()
            }}
          >
            eliminar
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
