import axios from "axios";

export const GetStoreByDomainAPI = async (subdomain) => {
  try {
    const { data } = await axios.get(
      `https://ventafazil-strapi-production.up.railway.app/dominios/?subdominio=${subdomain}`
    );
    if (data[0].cliente.whatsapp) {
      localStorage.setItem("phone", JSON.stringify(data[0].cliente.whatsapp));
    } else {
      localStorage.setItem("phone", null);
    }
    localStorage.setItem("user", JSON.stringify(data[0].cliente))
    return data[0].cliente;
  } catch (error) {
    console.log(error);
  }
};
