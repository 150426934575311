import React, { useContext, useState, useEffect } from 'react'
import { SessionContext } from '../Context/SessionContext'
import { LanguageContext } from '../Context/LanguageContext'
import { CurrencyContext } from '../Context/CurrencyContext'

export default function UIStorefrontCardTotals(props) {
  const { session, setSession } = useContext(SessionContext)
  const [coupon, setCoupon] = useState(
    JSON.parse(localStorage.getItem('coupon'))
  )
  const [cartTotal, setCartTotal] = useState(0)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const language = useContext(LanguageContext)
  const textCar = language.text.car

  useEffect(() => {}, [session])

  useEffect(() => {
    //BUGFIX
    let total = sumTotal(session)
    console.log('Sum no funca', total)
    console.log('mi total', total)
    let cuponJson = JSON.parse(localStorage.getItem('coupon'))
    console.log('IFFF', total)

    if (cuponJson?.benefit === 'percentage') {
      console.log('SIN COUPON', total)

      setCartTotal((total - (total * cuponJson.value) / 100).toFixed(2))
    }
    if (cuponJson?.benefit === 'discount') {
      console.log('SIN COUPON', total)

      setCartTotal((total - cuponJson.value).toFixed(2))
    }
    if (cuponJson?.benefit === 'freeshipping') {
      console.log('SIN COUPON', total)

      setCartTotal(total.toFixed(2))
    }
    if (!localStorage.getItem('coupon')) {
      console.log('SIN COUPON', total)

      setCartTotal(total.toFixed(2))
    }
  }, [session, coupon])

  let sumTotal = (arr) => {
    // return Number(
    //   arr
    //     .map((x) => x.precio * x.amount)
    //     .reduce((a, b) => Number(a) + Number(b), 0)
    // )

    const maprr = arr.map((x) => {
      const amount = x.amount || 1
      return x.precio * amount
    })
    return Number(maprr.reduce((a, b) => Number(a) + Number(b), 0))
  }

  return (
    <div className='flex flex-col w-full px-6 py-6 mt-6 mb-1 bg-white rounded-xl'>
      {props.pricedelivery && props.pricedelivery !== null ? (
        <div className='flex justify-between w-full'>
          <div className='pr-10 text-sm'>Costo de envio:</div>
          <div className='text-lg font-bold'>
            {currency + ' '} {props.pricedelivery ? props.pricedelivery : 0}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='flex justify-between w-full'>
        <div className='pr-10 text-sm'>{textCar.total}</div>
        {props.pricedelivery !== null ? (
          <div className='flex flex-row items-center gap-2 text-lg font-bold shrink-0'>
            {coupon !== null ? (
              <>
                <div className='text-right opacity-40'>
                  <div className='text-xs line-through '>
                    {currency + ' ' + sumTotal(session)}
                  </div>
                  <div className='text-xxs'>cupón aplicado22</div>
                </div>
                <div className='text-md'>{currency}</div>
                <div className='text-lg'>{cartTotal}</div>
              </>
            ) : (
              <>
                <div className='text-sm'>{currency}</div>

                {sumTotal(session)}
              </>
            )}
          </div>
        ) : (
          <div className='flex flex-row gap-2 text-lg font-bold'>
            {coupon !== null ? (
              <>
                <div className='text-right line-through opacity-40'>
                  <div className='text-xs'>{currency + ' ' + cartTotal}</div>
                  <div className='text-xxs'>cupón aplicado</div>
                </div>
                <div className='text-sm'>{currency}</div>
                {localStorage.getItem('newTotal')}
              </>
            ) : (
              <>
                <div className='text-sm'>{currency}</div>
                {cartTotal}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
