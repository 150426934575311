import React, { useContext } from 'react'
import { StoreContext } from '../Context/StoreContext'
import MinimalCategoriesList from '../Components/Minimal/MinimalCategoriesList'
import DeliciousCategoriesList from '../Components/Delicious/DeliciousCategoriesList'
import ServiceCategoriesList from '../Components/Service/ServiceCategoriesList'

export default function GetStore({ data, custom, unique, cat }) {
  // console.log('pruebaaaa', data)
  const { store } = useContext(StoreContext)
  if (store.theme === 'minimal') {
    return <MinimalCategoriesList data={data} classname={custom} />
  }
  if (store.theme === 'delicious') {
    return <DeliciousCategoriesList data={data} unique={unique} cat={cat} />
  }
  if (store.theme === 'service') {
    return <ServiceCategoriesList data={data} />
  }
}
