import React from 'react'
import DeliciousProductCard from './DeliciousProductCard'

export default function DeliciousGridXL({ data, nameCategory, category }) {
  console.log('micategory', category)
  console.log('Gfii')
  return (
    <div className='container'>
      <div className='mb-6 text-2xl font-semibold tracking-tighter md:text-4xl'>
        {nameCategory}
      </div>
      <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-6xl mx-auto grid-cols-1-xs justify-items-center items-center'>
        {data.map((product, index) => {
          return (
            <DeliciousProductCard
              md
              rounded
              overlay
              key={index}
              id={product._id}
              imagenproducto={product.imagenproducto}
              image={product.image}
              name={product.nombreproducto}
              price={product.precio}
              codigoproducto={product.codigoproducto}
            />
          )
        })}
      </div>
    </div>
  )
}
