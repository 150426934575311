import axios from 'axios'

export const PostVentasAPI = async (store, products, total, infouser) => {
  let body = JSON.stringify({
    productos: products,
    estado: 'pendiente',
    total: total,
    clientefinal: infouser.name + ' ' + infouser.surname,
    cliente: {
      _id: store,
    },
    metadata: [infouser],
  })

  try {
    const { data } = await axios.post(
      'https://ventafazil-strapi-production.up.railway.app/ventas',
      body,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,

          'Content-Type': 'application/json',
        },
      }
    )
    return data
  } catch (error) {
    console.log(error)
  }
}
