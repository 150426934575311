import React, { createContext, useState, useMemo } from "react";

const StoreContext = createContext({
    store: {},
    setStore: () => { },
});
// let datauser = JSON.parse(localStorage.getItem('user'))

const StoreContextProvider = ({ children }) => {
    const [store, setStore] = useState(
        {
            "id": "627a770b2c35fb0017422cdd",
            "theme": "delicious",
            "primaryColor": "#f5f5f5",
        }
    );
    const value = useMemo(() => ({ store, setStore }), [store]);

    return (
        <StoreContext.Provider value={value}>
            {children}
        </StoreContext.Provider>
    );
};

const StoreContextConsumer = StoreContext.Consumer;
export { StoreContext, StoreContextProvider, StoreContextConsumer };