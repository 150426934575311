import React from 'react'
import DeliciousProductsList from './DeliciousProductsList'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'

export default function DeliciousCategoriesList({ data, cat, unique }) {
  return (
    <div className='container'>
      {!unique
        ? data.map((category, index) => {
            if (category.productos.length > 0) {
              return (
                <DeliciousProductsList
                  key={index}
                  index={index}
                  products={category.productos}
                  id={category._id}
                  name={category.nombrecategoria}
                />
              )
            }
          })
        : data
            .filter((category) => category._id === cat._id)
            .map((category, index) => (
              <DeliciousProductsList
                key={index}
                index={index}
                products={category.productos}
                id={category._id}
                name={category.nombrecategoria}
              />
            ))}
    </div>
  )
}
