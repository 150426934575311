import React, { useState, useEffect, useContext } from 'react'
import KRGlue from '@lyracom/embedded-form-glue'
import { StoreContext } from '../Context/StoreContext'
import { LanguageContext } from '../Context/LanguageContext'
import UIStorefrontCardTotals from '../Components/UIStorefrontCartTotals'
import { GetKeychainAPI } from '../Utils/GetKeychain'
import { GetFormTokenAPI } from '../Utils/GetFormToken'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faSpinner, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { PostVentasAPI } from '../Utils/PostVentas'
import { SessionContext } from '../Context/SessionContext'
import { CurrencyContext } from '../Context/CurrencyContext'
import {
  metodoEntrega,
  metodoEntregaDelivery,
  metodoRecojo,
  regions,
} from '../Utils/data'
import { verifyStock } from '../Utils/VerifyStock'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import Carrito from './Carrito'
import Select from 'react-select'
import axios from 'axios'
let isHomeDelivery = JSON.parse(localStorage.getItem('user'))?.ishomedelivery
let ispickupstore = JSON.parse(localStorage.getItem('user'))?.ispickupstore
let isPricePerZone = JSON.parse(localStorage.getItem('user'))?.iszoneprice
let isOnlyPrices = JSON.parse(localStorage.getItem('user'))?.isonlyprice

console.log('home', isHomeDelivery)
console.log('pick', ispickupstore)
console.log('XSF', isPricePerZone)
console.log('XSA', isOnlyPrices)
export default function CheckoutFunction(props) {
  const history = useHistory()
  const { store, setStore } = useContext(StoreContext)
  const { session, setSession } = useContext(SessionContext)
  const language = useContext(LanguageContext)
  const textFinishBuy = language.text.finishBuy
  const [keychain, setKeychain] = useState(null)
  const [formToken, setFormToken] = useState(null)
  const [stepper, setStepper] = useState(0)
  const [saleID, setSaleID] = useState('')
  const [priceDelivery, setPriceDelivery] = useState(0)
  const [deliveryCondition, setDeliveryCondition] = useState('')
  const [priceDiscountDelivery, setPriceDiscountDelivery] = useState(0)
  const [isDiscountDelivery, setIsDiscountDelivery] = useState()
  const [listRegions, setListRegions] = useState(regions)
  const [isActiveButton, setIsActiveButton] = useState(false)
  const [activeFormButton, setactiveFormButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [freeshipping, setFreeshipping] = useState(false) //FREESHIPPING
  const [metodoEntrega1, setMetodoEntrega1] = useState(
    isHomeDelivery && ispickupstore
      ? metodoEntrega[0]
      : isHomeDelivery
      ? metodoEntregaDelivery[0]
      : metodoRecojo[0]
  )

  const [infoMessage, setInfoMessage] = useState()

  const [hasDelivery, setHasDelivery] = useState('')
  const [infodata, setInfodata] = useState({
    name: '',
    surname: '',
    email: '',
    mobile: '',
  })

  const validateSelect = () => {
    if (isHomeDelivery && ispickupstore) {
      // setMetodoEntrega1(metodoEntrega[0])
      return metodoEntrega
    } else if (isHomeDelivery) {
      // setMetodoEntrega1(metodoEntregaDelivery[0])

      return metodoEntregaDelivery
    } else {
      // setMetodoEntrega1(metodoRecojo[0])

      return metodoRecojo
    }
  }

  const [selectedItem, setSelectedItem] = useState({
    name: '',
    direccion: '',
    lugar: '',
  })
  const handleCheckboxChange = (item) => {
    setSelectedItem({
      name: item.name,
      direccion: item.adress,
      place: item.place,
    })
  }

  let clientID = JSON.parse(localStorage.getItem('user')).id
  let userPais = JSON.parse(localStorage.getItem('user')).paises

  const [tiendaRecojo, setTiendaRecojo] = useState([])
  const consultarData = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/Pickpoints/?cliente=${clientID}&country=${userPais}&isactive=true`
    try {
      const response = await axios.get(url)
      console.log('DDSAS', response.data)
      setTiendaRecojo(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const [onlyPrices, setOnlyPrices] = useState([])

  const [thirdDivi, setThirdDivi] = useState([])
  const [onlyPricesDel, setOnlyPricesDel] = useState([])
  const consultarOnlyPrices = async () => {
    let url

    try {
      if (isOnlyPrices) {
        url = `https://ventafazil-strapi-production.up.railway.app/Onlyprices?cliente=${clientID}&country=${userPais}`
        const response = await axios.get(url)

        let idThreeDivision = []
        setOnlyPricesDel(response.data)

        console.log('MI DATA', response.data)
        const onPrices = response.data[0].delivery[1][0]
        console.log('onlll', onPrices)
        console.log('TEST ISONLY', isOnlyPrices)

        onPrices.forEach((element) => {
          idThreeDivision.push(element.split('_ID').pop())
        })
        console.log('FFFE', idThreeDivision)
        setThirdDivi(thirdDivi)
        // consultarTwoDivision(idThreeDivision)
        consultarTwoDivisionMX(idThreeDivision)
      } else {
        url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones?cliente=${clientID}&country=${userPais}`

        const response = await axios.get(url)

        const pricePerzones = response.data.map((data) => {
          let idCiudades = []

          data.deliverylist[0].forEach((element) => {
            idCiudades.push(element.split('_ID').pop())
          })

          console.log('fddss', idCiudades)

          return {
            discountprice: data.discountprice,
            price: data.price,
            isdiscount: data.isdiscount,
            deliverycondition: data.deliverycondition,
            deliverylist: idCiudades,
            ratename: data.ratename,
          }
        })

        console.log('NEW DATA', pricePerzones)
        // console.log('CIUDAD', idCiudades)
        consultarTwoDivisionMX(pricePerzones)
      }
    } catch (error) {
      setLoading(false)
    }
  }
  const arrayOne = []

  const [mexTowOptions, setMexTowOptions] = useState([])
  const [mexOneDivision, setMexOneDivision] = useState([])
  const [oneDivisionUnique, setOneDivisionUnique] = useState('')
  const [mexTwoDivision, setMexTwoDivision] = useState([])
  const [twoDivisionUnique, setTwoDivisionUnique] = useState('')

  let mapOptions

  const consultarTwoDivisionMX = async (idThreeDivision) => {
    const url = `https://ventafazil-strapi-production.up.railway.app/oneDivisions?&_limit=-1&country=${userPais}`
    const response = await axios.get(url)

    if (isOnlyPrices) {
      try {
        const _oneDiv = response?.data

        const oneOptions = response.data.map((option) => ({
          id: option.id,
          value: option.name,
          label: option.name,
        }))
        _oneDiv.forEach((elemento) => {
          const dateD = elemento.twodivisions.filter((ef) => {
            if (idThreeDivision.includes(ef._id)) {
              arrayOne.push(ef)
            }
          })
          mapOptions = arrayOne.map((option) => ({
            id: option.id,
            value: option.name,
            label: option.name,
            oneDivision: option.onedivision,
          }))
          setMexTowOptions(mapOptions)
        })
        const oneDivision = [
          ...new Set(arrayOne.map((item) => item.onedivision)),
        ]
        console.log('FXS', oneDivision)
        console.log('PRIVVV', mapOptions)

        const filteredOneDivision = oneOptions.filter((objeto) => {
          return oneDivision.includes(objeto.id)
        })
        console.log('PRVV', filteredOneDivision)
        setMexOneDivision(filteredOneDivision)
      } catch (error) {}
    } else {
      console.log('DESDE EL ELSE', JSON.stringify(idThreeDivision))

      const oneDiviv = response.data.map((data) => {
        return {
          id: data.id,
          name: data.name,
          twodivisions: data.twodivisions,
        }
      })

      let result = []

      console.log('municipios', idThreeDivision)

      console.log('estados', oneDiviv)

      for (let i = 0; i < idThreeDivision.length; i++) {
        let deliverylist = idThreeDivision[i].deliverylist
        let estado = ''
        let municipio = ''
        console.log('Fddx', deliverylist)
        for (let j = 0; j < oneDiviv.length; j++) {
          let twodivisions = oneDiviv[j].twodivisions

          for (let k = 0; k < twodivisions.length; k++) {
            if (deliverylist.includes(twodivisions[k].id)) {
              municipio = twodivisions[k].name
              estado = oneDiviv[j].name
              result.push({
                price: idThreeDivision[i].price,
                estado: estado,
                label: municipio,
                value: municipio,
                deliverycondition: idThreeDivision[i].deliverycondition,
              })
            }
          }
        }
      }
      const estados = [...new Set(result.map((item) => item.estado))]
      const estadosTransformados = estados.map((estado) => {
        return {
          label: estado,
          value: estado,
        }
      })
      setMexTowOptions(result)

      setMexOneDivision(estadosTransformados)
      console.log('5445', estadosTransformados)
      console.log('PROBB', result)
    }
  }

  useEffect(() => {
    consultarData()
  }, [])
  useEffect(() => {
    consultarOnlyPrices()
  }, [])
  // useEffect(() => {
  //   consultarTwoDivision()
  // }, [])
  const [cartTotal, setCartTotal] = useState(0)
  const [coupon, setCoupon] = useState(
    JSON.parse(localStorage.getItem('coupon'))
  )
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const textCar = language.text.car
  const verify = async () => {
    setLoading(true)
    const data = await verifyStock()

    console.log('DATAAAAAA PRRRR', data.join(', '))
    if (data.length >= 1) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Hubo un problema...',
        text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
          ', '
        )}
          por favor elimina esos productos
          
          `,
      })
    } else {
      setLoading(false)
      // history.push('/checkout')

      // console.log('checkout')
    }
  }

  useEffect(() => {
    //BUGFIX
    let total = sumTotal(session)
    console.log('entrandoFF', total)

    let cuponJson = JSON.parse(localStorage.getItem('coupon'))
    console.log('IFFF', total)
    console.log('CUPON', cuponJson)

    if (cuponJson?.benefit === 'percentage') {
      console.log('SIN COUPON', total)

      setCartTotal((total - (total * cuponJson.value) / 100).toFixed(2))
    }
    if (cuponJson?.benefit === 'discount') {
      console.log('SIN COUPON', total)

      setCartTotal((total - cuponJson.value).toFixed(2))
    }
    if (cuponJson?.benefit === 'freeshipping') {
      console.log('SIN COUPON11', total)

      setCartTotal(total.toFixed(2))
    }
    if (!localStorage.getItem('coupon') || cuponJson === null) {
      console.log('SIN COUPON', total)

      setCartTotal(total.toFixed(2))
    }
  }, [])

  // FUNCION FREESHIPPING
  useEffect(() => {
    const shipping = JSON.parse(localStorage.getItem('coupon'))
    if (shipping !== null) {
      if (shipping?.benefit === 'freeshipping') {
        setFreeshipping(true)
        setPriceDelivery(0)
      } else {
        setFreeshipping(false)
      }
    } else {
      setFreeshipping(false)
    }
  }, [localStorage])

  let sumTotal = () => {
    const maprr = session.map((x) => {
      const amount = x.amount || 1
      return x.precio * amount
    })

    return Number(maprr.reduce((a, b) => Number(a) + Number(b), 0))
  }

  let totalPrice = () => {
    return (Number(cartTotal) + Number(priceDelivery ? priceDelivery : 0)) * 100
  }
  useEffect(() => {
    // modificarrr
    setStepper(0)

    GetKeychainAPI(store.id).then((response) => {
      setKeychain(response)
    }) //GET KEYCHAIN
  }, [])

  useEffect(() => {
    if (
      stepper === 1 &&
      infodata.name !== '' &&
      infodata.surname !== '' &&
      infodata.email !== '' &&
      infodata.mobile !== ''
    ) {
      console.log('METODOS DE ENTREGA')

      if (!metodoEntrega1.value && selectedItem.name) {
        console.log('recojo en tienda')
        setactiveFormButton(true)
      }
      // console.log('Fedx', oneDivisionUnique.value)
      // console.log('Fedx11', twoDivisionUnique.value)

      // if (
      //   metodoEntrega1.value &&
      //   oneDivisionUnique.value &&
      //   twoDivisionUnique.value &&
      //   infodata.postalCode !== '' &&
      //   infodata.addres !== ''
      // ) {
      //   console.log('DELIVERY')

      //   setactiveFormButton(true)
      // }
    } else {
      setactiveFormButton(false)
    }
  }, [infodata, selectedItem])

  useEffect(() => {
    console.log('metodoEntrega1', metodoEntrega1)
    console.log(
      infodata.email,
      infodata.mobile,
      metodoEntrega1.value,
      oneDivisionUnique.value,
      twoDivisionUnique.value,
      infodata.postalCode,
      infodata.addres
    )
    console.log('oNEDIV', oneDivisionUnique)
    console.log('twoDivisionUnique', infodata.postalCode)

    if (
      stepper === 1 &&
      infodata.name !== '' &&
      infodata.surname !== '' &&
      infodata.email !== '' &&
      infodata.mobile !== '' &&
      metodoEntrega1.value &&
      oneDivisionUnique.value &&
      twoDivisionUnique.value &&
      infodata.postalCode !== undefined &&
      infodata.postalCode !== '' &&
      infodata.addres !== ''
    ) {
      setactiveFormButton(true)
      console.log('verDARERO')
    } else {
      setactiveFormButton(false)
      console.log('dsser')
    }
  }, [infodata, oneDivisionUnique, twoDivisionUnique])

  // SE CREA LA VENTA
  function createSale() {
    let products = session.map((product) => {
      return { _id: product.id }
    })

    const coupon1 = JSON.parse(localStorage.getItem('coupon'))
    let delivery

    console.log('TEST DEL UNIQUE', twoDivisionUnique.label)
    if (metodoEntrega1.value) {
      let newValue = oneDivisionUnique.label + ', ' + twoDivisionUnique.label
      delivery = {
        label: newValue,
        value: ` ${oneDivisionUnique.label}, ${twoDivisionUnique.label} `,
        price: priceDelivery,
      }
    }

    let arr = {}
    if (coupon1 && coupon.benefit) {
      arr.benefit = coupon1.benefit
      arr.name = coupon1.name
      arr.value = coupon1.value
    }

    console.log('MY COUPON PRUEBA', arr)

    let detailProducts = Object.assign(
      infodata,
      { metodoEntrega: metodoEntrega1.value ? 'Delivery' : 'Punto de recojo' },
      { puntoRecojo: selectedItem },
      { detailProducts: session },
      metodoEntrega1.value && { delivery: delivery },
      { coupon: arr }
    )
    //!CORREGIR
    PostVentasAPI(store.id, products, totalPrice(), detailProducts) //NOMBRE COMPRADOR DEbe de venir del formulario
      .then((response) => {
        setSaleID(response.id)
        gettoken(response.id, infodata.email)
      })
      .catch((err) => console.log('AQUI VA', err))
  }
  // ESTE NECESITA EL ID DE LA VENTA Y EMAIL YA CARGADO
  function gettoken(saleID, userEmail) {
    console.log('PricDeluvery', priceDelivery)
    console.log("'PRICCEE'", (Number(cartTotal) + Number(priceDelivery)) * 100)

    console.log('CART TOTA', cartTotal)
    console.log('delivery', priceDelivery)
    GetFormTokenAPI(
      (Number(cartTotal) + Number(priceDelivery ? priceDelivery : 0)) * 100,
      store.currency,
      saleID,
      userEmail,
      window.btoa(
        `${keychain.userapirest}:${keychain.passwordproductionapirest}`
      )
    ) //PRODUCCION
      // GetFormTokenAPI(totalPrice(), store.currency, saleID, userEmail, window.btoa(`${keychain.userapirest}:${keychain.passwordtestapirest}`)) //TEST
      .then(async (response) => {
        console.log('lorem  prordsfjkjdfkjdsf sdj aj djads jdsajkadsjk')

        setFormToken(response.formToken)
        console.log('formToken', response)
        console.log('Fff')
        console.log(keychain)
        LoadPaymentForm(response.formToken)
        setLoading(false)
      }) //GEt FormToken console.log("izi-formtoken-READY", response.formToken);
  }
  // PIDE EL FORM TOKEN

  function LoadPaymentForm(token) {
    console.log('TOKEN', token)
    console.log('KEYCHANIN', keychain)
    let pasa = false
    setStepper(2)
    KRGlue.loadLibrary(
      keychain.endpoint,
      keychain.publickeyproductionjavascript,
      formToken
    ) //PRODUCCION
      // KRGlue.loadLibrary(keychain.endpoint, keychain.publickeytestjavascript, formToken)//TEST

      .then(({ KR }) =>
        KR.setFormConfig({
          // 'kr-get-url-success': '/thankyou/ ',
          'kr-get-url-refused': '/refused/ ',
          formToken: token,
          'kr-language': 'es-ES',
        })
      )
      .then(({ KR }) => KR.addForm('#myPaymentForm'))
      .then(({ KR, result }) => KR.showForm(result.formId))
      .then(({ KR }) => {
        setStepper(2)

        KR.onSubmit(async (resp) => {
          console.log('ENTRANDO PAGOS')
          console.log('respuesta KR', resp)
          const data = await verifyStock()
          if (data.length >= 1) {
            Swal.fire({
              icon: 'error',
              title: 'Hubo un problema...',
              text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
                ', '
              )}
        por favor elimina esos productos
        
        `,
            })
            Promise.reject()
            throw new Error('Producto sin stock')
          } else {
            // return false
            history.push(
              '/thankyou/?kr-answer=' + encodeURIComponent(resp.rawClientAnswer)
            )
          }
          return false
        })

        // KR.addForm('#myPaymentForm')
      })

      .catch((error) => {
        console.log(error)
        // debugger
      })
  }

  function onChangeForm(event) {
    let newdatainfo = Object.assign({}, infodata)
    newdatainfo[event.target.name] = event.target.value
    if (
      newdatainfo[event.target.name] === '' ||
      newdatainfo[event.target.name] === 'null'
    ) {
      setIsActiveButton(false)
    }
    setInfodata(newdatainfo)
  }
  const onChangeDeliveryForm = () => {}

  const envioStep1 = async () => {
    setLoading(true)
    const data = await verifyStock()
    console.log(data.join(', '))
    if (data.length >= 1) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Hubo un problema...',
        text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
          ', '
        )}
          por favor elimina esos productos
          
          `,
      })
    } else {
      setLoading(false)
      setStepper(1)
    }
  }

  const envioStep2 = async () => {
    // console.log('PPPPPffs')
    setLoading(true)
    const data = await verifyStock()
    if (data.length >= 1) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Hubo un problema...',
        text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
          ', '
        )}
          por favor elimina esos productos

        `,
      })
    } else {
      createSale()
    }
  }

  const handleSelectMetodo = (selectedOption) => {
    if (selectedOption === metodoEntrega1) {
      return // no hacer nada si el elemento seleccionado es el mismo que el anterior
    }
    setactiveFormButton(false)

    setSelectedItem({})
    const newObject = { ...infodata }
    delete newObject.addres
    delete newObject.postalCode
    setInfodata(newObject)
    setMetodoEntrega1(selectedOption)
    setPriceDelivery(0)
    if (!selectedOption.value) {
      setOneDivisionUnique('')

      setTwoDivisionUnique('')
    }
  }

  const handleSelectChange = (e) => {
    console.log('fds', e)

    setOneDivisionUnique(e)
    setTwoDivisionUnique('')
    setDeliveryCondition('')
    if (isOnlyPrices) {
      console.log('TEST ds', onlyPricesDel[0].price)
      setPriceDelivery(0)
      setPriceDiscountDelivery('')
      setIsDiscountDelivery('')
    }
    if (isPricePerZone) {
      setPriceDelivery(0)
    }
  }

  const handleSelectChange1 = (e) => {
    console.log('valor MUNICIPIO 1', e)

    setTwoDivisionUnique(e)
    console.log('ONLUVVV', onlyPricesDel)
    if (isOnlyPrices) {
      console.log('TEST ds', onlyPricesDel[0].price)
      setPriceDelivery(onlyPricesDel[0].price)
      setPriceDiscountDelivery(onlyPricesDel[0].discountprice)
      setIsDiscountDelivery(onlyPricesDel[0].isdiscount)
      setDeliveryCondition(onlyPricesDel[0].deliverycondition)
    }
    if (isPricePerZone) {
      setPriceDelivery(e.price)

      setDeliveryCondition(e.deliverycondition)
    }

    // setSelectEstadoVenta(e.value)
  }

  useEffect(() => {
    if (oneDivisionUnique) {
      console.log('entr')
      console.log('entr', isOnlyPrices)

      if (isOnlyPrices) {
        console.log('llegamos', oneDivisionUnique)
        console.log('llegamos aquii')
        console.log('llegamos', mexTowOptions)
        const _twoDv = mexTowOptions.filter((label) => {
          return label.oneDivision == oneDivisionUnique.id
        })

        setMexTwoDivision(_twoDv)
      } else {
        console.log('llegamos', oneDivisionUnique)
        console.log('llegamos1', mexTowOptions)
        const _twoDv = mexTowOptions.filter((label) => {
          return label.estado == oneDivisionUnique.label
        })

        console.log('entr 111', _twoDv)
        setMexTwoDivision(_twoDv)
      }
    }
  }, [oneDivisionUnique])

  return (
    <div className='pt-16 pb-12 bg-gray-100 md:pt-12'>
      {' '}
      <div className='container flex flex-col items-start gap-6 md:flex-row'>
        <div className='flex py-4 flex-col w-full lg:mb-24 grow'>
          <h2 className='max-w-lg px-2 pb-6 text-xl font-bold tracking-tighter text-center md:text-left md:text-3xl'>
            {' '}
            {textFinishBuy.tFinishBuy}
          </h2>

          <div className='flex justify-between w-full max-w-sm mx-auto my-4  px-2 '>
            <div className='flex flex-col items-center'>
              <div
                className={`flex items-center justify-center w-8 h-8 font-bold text-white ${
                  stepper === 0 ? 'bg-yellow-500' : 'bg-gray-400'
                } rounded-full shrink-0`}
              >
                1
              </div>
              <div className='text-xs'>{textFinishBuy.steps.step1}</div>
            </div>
            <div className='flex flex-col items-center'>
              <div
                className={`flex items-center justify-center w-8 h-8 font-bold text-white ${
                  stepper === 1 ? 'bg-yellow-500' : 'bg-gray-400'
                } rounded-full shrink-0`}
              >
                2
              </div>
              <div className='text-xs'>{textFinishBuy.steps.step2}</div>
            </div>
            <div className='flex flex-col items-center'>
              <div
                className={`flex items-center justify-center w-8 h-8 font-bold text-white ${
                  stepper === 2 ? 'bg-yellow-500' : 'bg-gray-400'
                } rounded-full shrink-0`}
              >
                3
              </div>
              <div className='text-xs'>{textFinishBuy.steps.step3}</div>
            </div>
          </div>

          <form className='flex flex-col gap-2 text-xs'>
            {stepper === 0 && <Carrito />}
            {stepper === 1 && (
              <div className='flex gap-10'>
                <div className='flex flex-col gap-2 p-4 bg-white border-lg rounded-xl  w-1/2'>
                  TUS DATOS
                  <input
                    type='text'
                    name='name'
                    placeholder={textFinishBuy.billingShipping.name}
                    className='w-full p-2 border border-gray-400 rounded-sm'
                    value={infodata.name}
                    onChange={onChangeForm}
                  ></input>
                  <input
                    type='text'
                    name='surname'
                    placeholder={textFinishBuy.billingShipping.lastName}
                    className='w-full p-2 border border-gray-400 rounded-sm'
                    value={infodata.surname}
                    onChange={onChangeForm}
                  ></input>
                  <input
                    type='text'
                    name='email'
                    placeholder={textFinishBuy.billingShipping.email}
                    className='w-full p-2 border border-gray-400 rounded-sm'
                    value={infodata.email}
                    onChange={onChangeForm}
                  ></input>
                  <input
                    type='text'
                    name='mobile'
                    placeholder={textFinishBuy.billingShipping.phone}
                    className='w-full p-2 border border-gray-400 rounded-sm'
                    value={infodata.mobile}
                    onChange={onChangeForm}
                  ></input>
                  <Select
                    className='w-full py-2 focus:border-yellow-500 '
                    closeMenuOnSelect={true}
                    value={metodoEntrega1}
                    // options={metodoEntrega}
                    options={validateSelect()}
                    onChange={handleSelectMetodo}
                  />
                </div>
                <div className='flex flex-col gap-2 p-4 bg-white border-lg rounded-xl w-1/2'>
                  MÉTODO DE ENTREGA
                  {metodoEntrega1?.value ? (
                    <div>
                      <p>Delivery</p>
                      <input
                        type='text'
                        name='addres'
                        placeholder={textFinishBuy.shippingInformation.address}
                        className='w-full p-2 border border-gray-400 rounded-sm'
                        value={infodata.addres}
                        onChange={onChangeForm}
                      ></input>
                      <Select
                        className='w-full py-2 focus:border-yellow-500 '
                        value={oneDivisionUnique}
                        options={mexOneDivision}
                        onChange={handleSelectChange}
                      />
                      <Select
                        className='w-full py-2 focus:border-yellow-500 '
                        closeMenuOnSelect={true}
                        options={mexTwoDivision}
                        value={twoDivisionUnique}
                        onChange={handleSelectChange1}
                      />
                      {/* <Select
                        className='w-full py-2 focus:border-yellow-500 '
                        closeMenuOnSelect={true}
                        // value={oneDivision}
                        // options={oneOptions}
                        // onChange={handleSelectChange1}
                      /> */}
                      <input
                        type='text'
                        name='postalCode'
                        placeholder={
                          textFinishBuy.shippingInformation.postalCode
                        }
                        className='w-full p-2 border border-gray-400 rounded-sm'
                        value={infodata.postalCode}
                        onChange={onChangeForm}
                      ></input>
                      <p>
                        (*)En caso no aparezca tu zona de reparto aún no esta
                        configurado dicha zona de envío
                      </p>
                      {deliveryCondition &&
                        `   CONDICIONES DE ENVIO : ${deliveryCondition}`}
                    </div>
                  ) : (
                    <div>
                      <p>Recojo en tienda</p>
                      <div>
                        {tiendaRecojo?.map((item) => {
                          return (
                            <div
                              key={item.id}
                              className='flex items-center gap-3 mt-2'
                            >
                              <input
                                type='radio'
                                id={item.name}
                                name='item'
                                value={item.name}
                                checked={selectedItem.name === item.name}
                                onChange={() => handleCheckboxChange(item)}
                                className='w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                              />
                              {/* 
                            <label
                              htmlFor={item.name}
                              className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                            >
                              {item.name}
                              {item.adress}
                              {item.place}
                              {item.conditions}
                            </label> */}
                              <div className='text-xs'>
                                <div> {item.name}</div>
                                <div> {item.adress}</div>
                                <div> {item.place}</div>
                                <div className=' text-center whitespace-pre-line md:max-w-md lg:pr-28 md:text-left'>
                                  {item.conditions}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {stepper === 2 && (
              <div className='flex flex-col gap-2 p-4 bg-white border-lg rounded-xl '>
                <div className='py-2 text-sm font-semibold text-gray-500 uppercase'>
                  {' '}
                  listo para completar tu pedido dssdd
                </div>
              </div>
            )}
          </form>
        </div>

        <div className='flex flex-col items-center justify-center w-full max-w-md bg-gray-100 shrink-0 md:max-w-sm form rounded-2xl relative'>
          <UIStorefrontCardTotals pricedelivery={priceDelivery} />
          {loading && (stepper == 0 || stepper == 1) && (
            <div className='flex justify-center items-center w-full h-full absolute'>
              <span>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className='spinner-border animate-spin rounded-full font-bold text-6xl text-blue-500'
                />
              </span>
            </div>
          )}
          <div className='flex flex-row justify-center w-full p-2 bg-white rounded'>
            {store.activeizi ? (
              <div>
                <div id='myPaymentForm' className=''>
                  {stepper === 0 ? (
                    <>
                      {/* {isActiveButton ? (
                        <div
                          onClick={envioStep1}
                          className={`self-end py-2 mx-4 text-white rounded-md cursor-pointer text-semibold ${
                            loading ? 'bg-gray-500' : 'bg-amber-500'
                          } px-14`}
                          disabled={loading}
                        >
                          {textFinishBuy.continueShipping.calculateShipping}
                        </div>
                      ) : (
                        <div className='self-end py-2 mx-4 text-white rounded-md cursor-pointer text-semibold bg-amber-200 px-14 '>
                          {textFinishBuy.continueShipping.calculateShipping}
                        </div>
                      )} */}

                      {/* DOTAA */}
                      <button
                        // className='flex justify-center w-full p-2 px-3 text-lg font-bold text-center transition-all bg-yellow-400 rounded-md shadow-black hover:shadow hover:-translate-y-1 text-md '
                        className={`flex justify-center w-full p-2 px-3 text-lg font-bold text-center transition-all ${
                          loading
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-yellow-400'
                        } rounded-md shadow-black hover:shadow hover:-translate-y-1 text-md `}
                        onClick={envioStep1}
                        disabled={loading}
                      >
                        {textCar.finishBuy.finishBuy}
                      </button>
                      {loading && (
                        <div className='flex justify-center items-center w-full h-full absolute'>
                          <span>
                            <FontAwesomeIcon
                              icon={faSpinner}
                              className='spinner-border animate-spin rounded-full font-bold text-8xl text-blue-500 '
                            />
                          </span>
                        </div>
                      )}
                      {/* <Link
            className='flex justify-center w-full p-2 px-3 text-lg font-bold text-center transition-all bg-yellow-400 rounded-md shadow-black hover:shadow hover:-translate-y-1 text-md'
            to={'/checkout/'}
          >
            Finalizar Compra
          </Link> */}
                    </>
                  ) : (
                    <>
                      {activeFormButton ? (
                        <div
                          onClick={envioStep2}
                          // className='self-end py-2 mx-4 text-white rounded-md cursor-pointer text-semibold bg-amber-500 px-14'
                          className={`self-end py-2 mx-4 text-white rounded-md cursor-pointer text-semibold ${
                            loading ? 'bg-gray-500' : 'bg-amber-500'
                          } px-14`}
                          disabled={loading}
                        >
                          {textFinishBuy.finishOrder}
                        </div>
                      ) : (
                        <div className='self-end py-2 mx-4 text-white rounded-md cursor-pointer text-semibold bg-amber-200 px-14'>
                          {textFinishBuy.finishOrder}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className='p-4 text-center text-xxs'>
                  Secured by <span className='font-bold'>Izipay</span>
                </div>
              </div>
            ) : (
              <button className='flex items-center gap-2 p-4 px-6 text-white transition-all transform bg-pink-500 rounded opacity-75 hover:opacity-100 hover:shadow-xl hover:-translate-y-1'>
                <span>
                  <FontAwesomeIcon icon={faWhatsapp} className='text-xl' />
                </span>
                Pedir por WhatsApp
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
