import axios from 'axios'

export const GetKeychainAPI = async (id) => {
  try {
    const { data } = await axios.get(
      `https://ventafazil-strapi-production.up.railway.app/keychains/?cliente=${id}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        },
      }
    )

    console.log('DSFSFDSFFDS', data)
    return data[0]
  } catch (error) {
    console.log(error)
  }
}
//**COMENTARIO PARA MARTIN** : ocultar formtoken de la linea 6 en las variables de entorno
