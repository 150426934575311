import React, { useContext, useState, useEffect } from 'react'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import './DeliciousSlider.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { StoreContext } from '../../Context/StoreContext'
import { rezizeCloudinary, transformCategory } from '../../Utils/utils'
import { CurrencyContext } from '../../Context/CurrencyContext'

export default function DeliciousSlider({ data, banners }) {
  const { store, setStore } = useContext(StoreContext)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const [width, setWidth] = useState(0)
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
    console.log('aaa')
  }, [setWidth])
  return (
    <div className=' w-full  md:p-0'>
      <div className='inline-block w-full my-2 overflow-hidden  h-auto'>
        <Slide className='p-0 m-0 -mr-5 from-rose-900 to-white p-4'>
          {banners && banners.length > 0
            ? banners.map((banner, i) => {
                return banner.urlbanner ? (
                  <a
                    href={banner.urlbanner !== '' ? banner.urlbanner : '#'}
                    target='_blank'
                    key={i + 'b'}
                    className='flex flex-col items-center justify-center w-full overflow-hidden h-auto object-cover'
                  >
                    <img
                      className='w-full h-auto md:h-full'
                      src={rezizeCloudinary(banner.link)}
                    />
                  </a>
                ) : (
                  <div
                    key={i + 'b'}
                    className='flex flex-col items-center justify-center w-full overflow-hidden h-auto object-cover'
                  >
                    <img
                      className='w-full h-auto md:h-full'
                      src={rezizeCloudinary(banner.link)}
                      style={{
                        height: 450,
                      }}
                    />
                  </div>
                )
              })
            : data.map((slider, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      backgroundImage: `url(${
                        slider.image && slider.image.length > 0
                          ? slider.image[0].url
                          : ''
                      })`,
                    }}
                    className={`flex flex-col bg-gray-200 p-6 w-full relative bg-center bg-cover h-128 `}
                  >
                    <div className='z-10 flex flex-col flex-grow'>
                      <div className='text-base font-semibold text-white'>
                        {transformCategory(slider.categorias)}
                      </div>
                      <div className='text-3xl font-bold leading-tight text-white'>
                        {slider.nombreproducto}
                      </div>
                    </div>
                    <Link
                      to={`/producto/${slider.id}`}
                      style={{
                        backgroundColor: store.primarycolor
                          ? store.primarycolor
                          : 'inherit',
                      }}
                      className='z-10 flex flex-row items-center self-start gap-2 p-3 py-2 text-lg font-bold text-black rounded-xl bg-primary'
                    >
                      <span
                        className='flex flex-row items-center gap-2 text-3xl font-bold tracking-tighter text-black'
                        style={{
                          color: store.primarycolor ? '#ffffff' : 'inherit',
                        }}
                      >
                        <span className='text-sm'>{currency} </span>
                        <span>{Number(slider.precio).toFixed(2)}</span>
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          className='text-lg '
                        />
                      </span>
                    </Link>
                    <div className='absolute top-0 bottom-0 left-0 right-0 flex w-full h-full opacity-100 from-transparent bg-gradient-to-tl to-black'></div>
                  </div>
                )
              })}
        </Slide>
      </div>
    </div>
  )
}
