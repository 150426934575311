import React, { Component } from 'react'
import DeliciousProductDescription from './DeliciousProductDescription'
import DeliciousProductDescriptionAlt from './DeliciousProductDescriptionAlt'

export default class DeliciousProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageSelect: null,
    }
    this.changeImageSelect = this.changeImageSelect.bind(this)
  }

  changeImageSelect(val) {
    this.setState({ imageSelect: val })
  }

  productImage(body) {
    return this.state.imageSelect === null
      ? body.imagenproducto !== ''
        ? body.imagenproducto
        : ''
      : this.state.imageSelect.url
  }

  render() {
    const date = '2022-12-15T04:27:00.861Z'

    return (
      <div className='relative '>
        <div className='flex mb-4'>
          <div className='container z-10 flex flex-col items-center justify-center h-auto gap-4 p-6 md:mt-4 md:my-4 md:flex-row md:items-start'>
            <div className='fle md:w-6/12  justify-center'>
              <div className='flex w-500 h-full  lg:w-4/5  bg-center bg-cover text-center '>
                <img
                  className='rounded-xl h-inherit'
                  style={{ width: '650px', height: '450px' }}
                  src={this.productImage(this.props.data)}
                  alt=''
                />
              </div>
            </div>

            <div className='flex w-full auto md:w-4/12 justify-center  '>
              <DeliciousProductDescription
                id={this.props.data.id}
                image={this.props.data.image}
                isproductstock={this.props.data.isproductstock}
                extras={this.props.data.extras}
                variations={this.props.data.variations}
                imagenproducto={this.props.data.imagenproducto}
                nombreproducto={this.props.data.nombreproducto}
                categoriaproducto={this.props.data.categorias}
                precio={this.props.data.precio}
                detalladoproducto={this.props.data.detalladoproducto}
                stock={this.props.data.stock}
                categorias={this.props.data.categorias}
                productvariations={this.props.data.productvariations}
                changeImage={this.changeImageSelect}
              />
            </div>
          </div>
        </div>
        <div className='flex mb-4'>
          <div className='container z-10 flex flex-col items-center justify-center h-auto gap-4 p-6  md:flex-row md:items-start'>
            <div className='grid justify-start grid-cols-3 gap-2 mb-6 w-6/12'>
              {this.props.data.image &&
                this.props.data.image.map((image, index) => (
                  <div
                    key={index}
                    onClick={() => this.changeImageSelect(image)}
                    style={{ backgroundImage: `url(${image.url})` }}
                    className='flex flex-shrink-0 w-full h-24 bg-center bg-cover border border-white cursor-pointer'
                  ></div>
                ))}
            </div>

            <div className='flex w-full auto md:w-4/12 justify-center  '></div>
          </div>
        </div>
      </div>
    )
  }
}
