import React from 'react'
import { StoreContext } from '../Context/StoreContext'
import { getProductsAPI } from '../Utils/GetProducts'
import { getCategoriesAPI } from '../Utils/GetCategories'
import GetCategories from '../Hooks/GetCategories'
import GetSlider from '../Hooks/GetSlider'
import GetSearchBar from '../Hooks/GetSearchBar'
import GetPromotions from '../Hooks/GetPromotions'
import GetAbout from '../Hooks/GetAbout'
import GetStore from '../Hooks/GetStore'
import GetFooter from '../Hooks/GetFooter'
import { getBannersAPI } from '../Utils/GetBanners'

export default class Home extends React.Component {
  static contextType = StoreContext
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      products: [],
      productsTrending: [],
      banners: [],
      specialCategory: [],
      productsNoCategory: [],
    }
  }
  componentDidMount() {
    getProductsAPI(this.context.store.id).then((response) => {
      this.setState({ products: response })
      let productsTrending = response
        .filter((el) => el.istrending)
        .sort((a, b) => a.order - b.order)
      this.setState({ productsTrending: productsTrending })
      let _productsNoCategory = response.filter(
        (el) => el.categorias.length == 0
      )
      this.setState({ productsNoCategory: _productsNoCategory })
      // console.log("getProductsAPI",response)
    }) //GEt PRODUCTS
    getCategoriesAPI(this.context.store.id).then(async (response) => {
      let specialCat = response.filter((el) => el.isspecial)
      console.log('getCategoriesAPI ', response)
      this.setState({ specialCategory: specialCat })
      this.setState({ categories: response })
      let _productos = this.state.productsNoCategory
      let _newCategorie = this.state.categories
      _newCategorie.push({
        nombrecategoria: 'Otros Productos',
        id: this.state.productsNoCategory.length.toString(),
        productos: _productos,
      })
      let newCategorie = _newCategorie
      this.setState({ categories: newCategorie })
    }) //GET CATEGORIES
    getBannersAPI(this.context.store.id).then((response) => {
      this.setState({ banners: response })
    }) //GET BANNERS
  }

  render() {
    return (
      <div>
        <GetCategories />
        <GetSlider
          data={this.state.products.slice(0, 3)}
          banners={this.state.banners}
        />
        <div className='px-4 md:px-0 py-8'>
          {/* <GetSearchBar /> */}
          {this.state.productsTrending.length > 0 && (
            <GetPromotions data={this.state.productsTrending} />
          )}

          {/* {this.state.specialCategory.length > 0 ? (
            <GetPromotions
              data={this.state.specialCategory[0].productos.slice(0, 4)}
              nameCategory={this.state.specialCategory[0].nombrecategoria}
            />
          ) : (
            ''
          )} */}
          <GetStore data={this.state.categories} />
        </div>
        <GetAbout data={this.context.store} />
        <GetFooter />
      </div>
    )
  }
}
