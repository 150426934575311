import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'

export default function DeliciousContact({ data, textContact }) {
  console.log(data.primeraimagennosotros)
  return (
    <div className='flex'>
      <div className='container   md:min-h-screen    md:pt-10 mb-6 md:mb-0'>
        <div className='flex flex-col justify-start h-auto lg:flex-row'>
          <div className='flex flex-col justify-center flex-grow max-w-sm p-4 ml-2 lg:pt-12'>
            <div className='items-center mb-12 text-2xl font-semibold tracking-tighter text-black'>
              {textContact.contactUs}
            </div>
            <div className='flex flex-col mb-4 tracking-tight'>
              <p>
                {textContact.email}{' '}
                <span className='font-bold'>{data.correoelectronico}</span>{' '}
              </p>
              <p>
                {textContact.phone} <span className='font-bold'>{data.telefono}</span>{' '}
              </p>
            </div>
            <div className='flex flex-col tracking-tight'>
              <p className='font-semibold tracking-tighter text-black'>
                {textContact.followUs}
              </p>
              {/* <a
                href={data ? data.socialmedia.facebook : '#'}
                target='_blank'
                rel='noreferrer'
                className='flex items-center gap-4 text-base font-light'
              >
                <FontAwesomeIcon icon={faFacebookF} /> Facebook
              </a>
              <a
                href={data ? data.socialmedia.twitter : '#'}
                target='_blank'
                rel='noreferrer'
                className='flex items-center gap-4 text-base font-light'
              >
                <FontAwesomeIcon icon={faTwitter} />
                Twitter
              </a>
              <a
                href={data ? data.socialmedia.instagram : '#'}
                target='_blank'
                rel='noreferrer'
                className='flex items-center gap-4 text-base font-light'
              >
                <FontAwesomeIcon icon={faInstagram} />
                Instagram
              </a> */}
              <div className='grid grid-cols-1 gap-2'>
                <div className='flex flex-col gap-4'>
                  {data.socialmedia?.facebook &&
                  data.socialmedia?.facebook !== '' ? (
                    <a
                      href={data ? data.socialmedia?.facebook : '#'}
                      target='_blank'
                      className='flex items-center gap-4 text-base font-light'
                    >
                      <FontAwesomeIcon icon={faFacebookF} /> Facebook
                    </a>
                  ) : (
                    ''
                  )}
                  {data.socialmedia?.twitter &&
                  data.socialmedia?.twitter !== '' ? (
                    <a
                      href={data ? data.socialmedia?.twitter : '#'}
                      target='_blank'
                      className='flex items-center gap-4 text-base font-light'
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                      Twitter
                    </a>
                  ) : (
                    ''
                  )}
                  {data.socialmedia?.instagram &&
                  data.socialmedia?.instagram !== '' ? (
                    <a
                      href={data ? data.socialmedia?.instagram : '#'}
                      target='_blank'
                      className='flex items-center gap-4 text-base font-light'
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                      Instagram
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>

            {/* <div className="text-2xl tracking-tighter text-black">¿Cómo podemos ayudarte?</div>
          <div className="pt-5 text-base text-black">Déjanos tus comentarios o preguntas y nuestro equipo te responderá a la brevedad. </div>
          <form>
          <input type="text" name="nombre" placeholder="Nombre"
          className="w-full px-6 py-2 my-2 text-lg border-2 border-gray-300 rounded-sm"></input>
          <input type="email" name="email" placeholder="Correo electrónico"
          className="w-full px-6 py-2 my-2 text-lg border-2 border-gray-300 rounded-sm"></input>
          <input type="text" name="lastname" placeholder="Apellido"
          className="w-full px-6 py-2 my-2 text-lg border-2 border-gray-300 rounded-sm"></input>
          <input type="text" name="mobile" placeholder="Celular"
          className="w-full px-6 py-2 my-2 text-lg border-2 border-gray-300 rounded-sm"></input>
          <div className="w-full mt-10">
          <div className="text-lg text-black">Mensaje</div>
          <textarea
          type="text"
          name="mobile"
          placeholder="Escribir mensaje..."
          className="w-full px-6 py-2 my-2 text-lg border-2 border-gray-300 rounded-sm resize-none h-28"
          ></textarea>
            </div>
            <button className="py-2 text-white rounded-md bg-amber-500 px-14">Enviar</button>
          </form> */}
          </div>
        </div>
      </div>

      <div
        className='absolute top-0 right-5 hidden w-[50rem] h-[35rem] bg-cover  md:flex -z-1'
        style={{ backgroundImage: `url(${data.primeraimagennosotros})` }}
      ></div>
    </div>
  )
}
