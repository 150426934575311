import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { CartContext } from '../../Context/CartContext'
import { SessionContext } from '../../Context/SessionContext'
import { StoreContext } from '../../Context/StoreContext'
import { CurrencyContext } from '../../Context/CurrencyContext'
import { rezizeCloudinary } from '../../Utils/utils'
import { useHistory } from 'react-router-dom'

export default function DeliciousProductSpecialCategory({
  imagenproducto,
  description,
  image,
  name,
  price,
  id,
  sm,
  md,
  xl,
  overlay,
  rounded,
  variations,
  stock,
}) {
  const { store, setStore } = useContext(StoreContext)
  const { cart, setCart } = useContext(CartContext)
  const { session, setSession } = useContext(SessionContext)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const [width, setWidth] = useState(0)
  const history = useHistory()

  // function AppendToCart(id, name, image, price, max) {
  //   console.log('Appending to cart')
  //   let body = {
  //     id: id,
  //     nombreproducto: name,
  //     imagenproducto: rezizeCloudinary(imagenproducto),
  //     precio: price,
  //   }
  //   let copyProducts = [...session]
  //   copyProducts.push(body)
  //   localStorage.setItem('session', JSON.stringify(copyProducts))
  //   setSession(copyProducts)
  //   setCart(true)
  // }

  function AppendToCart(id, name, image, price, max) {
    console.log('MAX222', max)
    if (!variations) {
      // history.push(`/producto/${id}`)

      const tempItem = session.find((i) => i.id === id)

      if (tempItem) {
        console.log('ENTRROOOO11111')
        const tempCart = session?.map((cartItem) => {
          if (cartItem.id === id) {
            console.log('ENTRAMOS FIRST IF')

            let newAmount = cartItem.amount + 1

            if (max == '') {
            } else if (newAmount > cartItem.max) {
              newAmount = cartItem.max
            }

            return { ...cartItem, amount: newAmount }
          } else {
            return cartItem
          }
        })
        setSession(tempCart)
        localStorage.setItem('session', JSON.stringify(tempCart))
      } else {
        console.log('ENTRROOOO22222')

        if (max == 0) {
          console.log('ENTRAEEE')
          return
        }

        let copyProducts = [...session]
        console.log('GGG')

        let body = {
          id: id,
          id1: id,
          nombreproducto: name,
          imagenproducto: rezizeCloudinary(imagenproducto),
          precio: price,
          amount: 1,
          max,
        }
        copyProducts.push(body)
        setSession(copyProducts)

        localStorage.setItem('session', JSON.stringify(copyProducts))
      }
      console.log('FF')

      setCart(true)
    } else if (variations.length >= 1) {
      history.push(`/producto/${id}`)
    } else {
      const tempItem = session.find((i) => i.id === id)

      if (tempItem) {
        console.log('ENTR')
        const tempCart = session?.map((cartItem) => {
          if (cartItem.id === id) {
            console.log('ENTRAMOS FIRST IF')

            let newAmount = cartItem.amount + 1

            if (max == '') {
            } else if (newAmount > cartItem.max) {
              newAmount = cartItem.max
            }

            return { ...cartItem, amount: newAmount }
          } else {
            return cartItem
          }
        })
        setSession(tempCart)
        localStorage.setItem('session', JSON.stringify(tempCart))
      } else {
        if (max == 0) {
          return
        }
        let copyProducts = [...session]
        let body = {
          id: id,
          id1: id,
          id2: id,
          nombreproducto: name,
          imagenproducto: rezizeCloudinary(imagenproducto),
          precio: price,
          amount: 1,
          max,
        }
        copyProducts.push(body)
        setSession(copyProducts)

        localStorage.setItem('session', JSON.stringify(copyProducts))
      }

      setCart(true)
    }
  }

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setWidth])
  let tranformText = (val) => {
    let newname = val.length > 28 ? val.substring(0, 35) + '...' : val
    return newname
  }
  // return (
  //   <div>
  //     <div
  //       style={{
  //         backgroundImage: `url(${imagenproducto !== ''
  //             ? rezizeCloudinary(imagenproducto)
  //             : image && image.length > 0
  //               ? rezizeCloudinary(image[0].url)
  //               : ''
  //           })`,
  //       }}
  //       className={`relative flex items-end justify-end p-4 overflow-hidden transition-all bg-center bg-cover bg-no-repeat ${rounded ? 'rounded-xl' : null
  //         } hover:-translate-y-1 hover:shadow-xl h-48 md:h-128`}
  //     >
  //       <a
  //         href={`/producto/${id}`}
  //         className='z-10 hidden md:flex items-center flex-grow text-lg font-semibold leading-5 tracking-tighter text-white capitalize hover:text-yellow-400'
  //       >
  //         {' '}
  //         {name}
  //       </a>
  //       <div
  //         onClick={() => AppendToCart(id, name, image, price, stock)}
  //         className=" bottom-2 z-10 cursor-pointer right-2 flex flex-row items-center after:text-sm after:opacity-0 delay-700 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex gap-1 px-1 pl-2 text-lg font-bold text-black rounded-full bg-white"
  //       >
  //         <span className='text-xs'> {currency}</span>
  //         {Number(price).toFixed(2)}
  //         <FontAwesomeIcon
  //           icon={faShoppingCart}
  //           className='text-sm text-black'
  //         />
  //       </div>
  //       <a
  //         href={`/producto/${id}`}
  //         className={`${overlay
  //             ? 'bg-gradient-to-t from-slate-900 via-transparent to-transparent'
  //             : null
  //           }  absolute top-0 bottom-0 left-0 right-0`}
  //       ></a>
  //     </div>
  //     <p className='text-sm font-semibold pt-1 md:hidden'>
  //       {tranformText(name)}
  //     </p>
  //   </div>
  // )
  return (
    <div className=' w-48  md:w-72 '>
      <div
        style={{
          backgroundImage: `url(${
            imagenproducto !== ''
              ? rezizeCloudinary(imagenproducto)
              : image && image.length > 0
              ? rezizeCloudinary(image[0].url)
              : ''
          })`,
        }}
        className='relative transition-all bg-center bg-cover rounded-xl hover:-translate-y-1 hover:scale-105 hover:shadow-xl h-48 w-48 md:h-72 md:w-72'
      >
        <a
          href={`/producto/${id}`}
          className={`absolute top-0 bottom-0 left-0 right-0`}
          alt={name}
        ></a>
      </div>
      <div className='  flex-grow gap-2 py-2 '>
        <a
          href={`/producto/${id}`}
          className='items-start justify-start flex-grow w-3/5 text-sm text-center  md:text-lg font-semibold leading-5 tracking-tighter capitalize hover:text-yellow-400'
        >
          {' '}
          {tranformText(name)}
        </a>
      </div>
      <div
        onClick={() => AppendToCart(id, name, image, price, stock)}
        style={{
          backgroundColor: store.primarycolor ? store.primarycolor : 'inherit',
          color: store.primarycolor ? '#fff' : 'inherit',
        }}
        className="mt-2 self-start bottom-2 cursor-pointer right-2 flex flex-row items-center after:text-sm after:opacity-0 delay-700 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex gap-1 px-1 pl-2 text-lg font-bold text-black rounded-full bg-primary w-1/2"
      >
        <span className='text-xs'> {currency}</span>
        <p className='text-xs md:text-lg'>{Number(price).toFixed(2)}</p>
        <FontAwesomeIcon icon={faShoppingCart} className='text-sm' />
      </div>
    </div>
  )
}
