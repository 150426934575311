import axios from "axios";

export const getProductsAPI = async (id) => {
  try {
    const { data } = await axios.get(
      `https://ventafazil-strapi-production.up.railway.app/productos/?cliente=${id}`
    );
    //console.log("productosapi", data)
    return data;
  } catch (error) {
    console.log(error);
  }
};
