import React, { useContext } from 'react'
import { StoreContext } from '../Context/StoreContext'
import MinimalFooter from '../Components/Minimal/MinimalFooter'
import DeliciousFooter from '../Components/Delicious/DeliciousFooter'
import ServiceFooter from '../Components/Service/ServiceFooter'

export default function GetFooter() {
  const { store, setStore } = useContext(StoreContext)
  if (store.theme === 'minimal') {
    return <MinimalFooter />
  }
  if (store.theme === 'delicious') {
    return <DeliciousFooter />
  }
  if (store.theme === 'service') {
    return <ServiceFooter />
  }
  // return <h1>prueba</h1>
}
