import react from 'react'
import GetCategories from '../Hooks/GetCategories'
import GetCategory from '../Hooks/GetCategory'
import GetCategoryHeader from '../Hooks/GetCategoryHeader'
import GetFooter from '../Hooks/GetFooter'
import { getCategoryAPI } from '../Utils/GetCategory'

export default class Category extends react.Component {
  constructor() {
    super()
    this.state = {
      category: [],
      loaded: false,
    }
  }

  componentDidMount() {
    getCategoryAPI(this.props.match.params.categoryid).then((response) => {
      this.setState({ category: response })
      this.setState({ loaded: true })
    })
  }

  render() {
    return (
      <>
        {this.state.loaded ? (
          <>
            <div className='px-6 md:px-0  pb-20'>
              <GetCategories />
              <GetCategoryHeader data={this.state.category} />
              <GetCategory data={this.state.category.productos} category />
            </div>
            {/* <GetFooter /> */}
          </>
        ) : null}
      </>
    )
  }
}
