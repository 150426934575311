import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

export default function DeliciousCategoryGrid({ data }) {
    return (
        <>
            <div className='container p-4 '>
                <div className='flex flex-col items-center gap-4 my-8'>
                    <Link className="flex items-center gap-2" to={`/`}>
                        <FontAwesomeIcon className='text-2xl' icon={faArrowLeft} /> regresar a home
                    </Link>
                    <div className='text-4xl font-bold tracking-tighter '>
                        Productos
                    </div>
                    <div>{data.length} Categorias</div>
                </div>
            </div>

            <div className='container p-4 '>
                <div className='grid grid-cols-1 gap-6 mb-6 md:grid-cols-2'>
                    {data.map((category, index) => {
                        console.log(category, "asdasd")
                        return (
                            <div key={index}>
                                <div style={{ backgroundImage: `url(${category.thumbnail && category.thumbnail !== "" ?category.thumbnail : category.productos.length > 0 ? category.productos[0].imagenproducto : ""})` }} className="relative flex items-start justify-end p-4 overflow-hidden transition-all bg-center bg-cover rounded-xl hover:-translate-y-1 hover:shadow-xl h-128" >
                                    <Link to={`/category/${category.id}`} className="z-10 flex items-center flex-grow text-lg font-semibold leading-5 tracking-tighter text-white capitalize hover:text-yellow-400"> {category.nombrecategoria}</Link>
                                    <Link to={`/category/${category.id}`} className={` bg-gradient-to-b  from-slate-900 via-transparent to-transparent absolute top-0 bottom-0 left-0 right-0`}></Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}