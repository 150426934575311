import React, { useContext, useRef } from 'react'
import DeliciousProductCard from './DeliciousProductCard'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { StoreContext } from '../../Context/StoreContext'
export default function DeliciousProductsList({ products, name, id, index }) {
  const spaceScroll = useRef(0)
  const { store, setStore } = useContext(StoreContext)

  let gorigth = () => {
    console.log('Ffed', index)
    let element = document.getElementById(`concat-${index}`)
    sideScroll(element, 'right', 80, 300, 200)
    // spaceScroll.current += 300
    // console.log(element.scrollLeft)
    // element.scrollLeft = spaceScroll.current
  }
  let goleft = () => {
    console.log('Fsddfdc', index)

    let element = document.getElementById(`concat-${index}`)
    sideScroll(element, 'left', 80, 300, 200)
    // spaceScroll.current -= 300
    // console.log(element.scrollLeft)
    // element.scrollLeft = spaceScroll.current
  }
  function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0
    var slideTimer = setInterval(function () {
      if (direction == 'left') {
        element.scrollLeft -= step
      } else {
        element.scrollLeft += step
      }
      scrollAmount += step
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer)
      }
    }, speed)
  }
  // const Arrow = ({ text, className }) => {
  //   return <div className={className}>{text}</div>
  // }
  // const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' })
  // const ArrowRight = Arrow({ text: '>', className: 'arrow-next' })
  return (
    <div className='pb-4 mb-12 border-b border-gray-300'>
      <div
        id='gridProductLg'
        className='w-full   text-2xl font-bold tracking-wider '
      >
        <div className='text-center'>{name}</div>
      </div>
      <div className='flex'>
        {products.length > 3 && (
          <div className='hidden md:flex gap-8'>
            <button className='px-2 text-4xl' onClick={(e) => goleft(e)}>
              {'<'}
            </button>
          </div>
        )}
        <div
          id={`concat-${index}`}
          className='flex flex-row gap-6 py-4 overflow-x-scroll no-scrollbar'
        >
          {products.length > 0
            ? products.slice(0, 12).map((product, index) => {
                return (
                  <DeliciousProductCard
                    md
                    key={index}
                    description={product.description}
                    id={product.id}
                    imagenproducto={product.imagenproducto}
                    stock={product.stock}
                    image={product.image}
                    name={product.nombreproducto}
                    price={
                      product.precio ||
                      (product?.productvariations?.length >= 1 &&
                        product.productvariations[0].precio)
                    }
                    variations={product.variations}
                  />
                )
              })
            : 'no hay productos'}
        </div>
        {products.length > 3 && (
          <div className='hidden md:flex gap-8'>
            <button className='px-2 text-4xl' onClick={(e) => gorigth(e)}>
              {'>'}
            </button>
          </div>
        )}
      </div>
      {products.length > 12 && (
        <p
          className='text-right mx-14 cursor-pointer  '
          style={{
            color: store.primarycolor ? store.primarycolor : 'inherit',
          }}
        >
          Ver más
        </p>
      )}
    </div>
  )
}
