import React from 'react'

export default function DeliciousAboutUs({ data, textWe }) {
  return (
    <div className='py-12'>
      <div className='container mb-12'>
        <div className='text-5xl font-semibold tracking-tighter text-center md:text-left'>
          {textWe}
        </div>
      </div>
      <div className='container flex flex-col gap-4 pb-12 md:flex-row'>
        <div className='flex flex-col gap-2 '>
          <div className='text-lg font-bold tracking-tighter text-center md:text-left'>
            {data.Ntorazonsocial}
          </div>
          <div className='mt-6 text-sm text-center whitespace-pre-line md:max-w-md lg:pr-28 md:text-left'>
            {data.about.info}
          </div>
        </div>
        <div className='flex flex-grow rounded-2xl'>
          <img
            className='object-cover w-full    md:h-144 rounded-2xl'
            src={data.primeraimagennosotros}
            alt=''
          />
        </div>
      </div>
    </div>
  )
}
