import React, { Component } from 'react'
import { StoreContext } from '../../Context/StoreContext'
import { getCategoriesAPI } from '../../Utils/GetCategories'

export default class MinimalCategories extends Component {
  static contextType = StoreContext

  constructor() {
    super()
    this.state = { categories: [] }
  }

  componentDidMount() {
    getCategoriesAPI(this.context.store.id).then((response) => {
      console.log('cat', response)
      this.setState({ categories: response })
    }) //GET CATEGORIES
  }

  render() {
    return (
      <div className='absolute left-0 right-0 z-30 flex flex-row gap-2 px-4 pt-20 overflow-x-scroll top-1 no-scrollbar'>
        <div className='container flex flex-row gap-6 '>
          {this.state.categories.length > 0 &&
            this.state.categories.map((category, index) => {
              return (
                <a
                  key={index}
                  href={`/category/${category.id}`}
                  className={`flex items-center gap-2 flex-row font-serif text-black`}
                >
                  <div
                    className='w-8 h-8 bg-white bg-center bg-cover rounded-full'
                    style={{
                      backgroundImage: `url(${
                        category.thumbnail && category.thumbnail !== ''
                          ? category.thumbnail
                          : category.productos.length > 0
                          ? category.productos[0].imagenproducto
                          : ''
                      })`,
                    }}
                  ></div>
                  <span className='flex text-sm font-semibold tracking-widest uppercase transition-all duration-700 opacity-50 shrink-0 hover:opacity-100 grow'>
                    {category.nombrecategoria}
                  </span>
                </a>
              )
            })}
        </div>
      </div>
    )
  }
}
