import React, { useState } from 'react'
import DeliciousProductSpecialCategory from './DeliciousProductSpecialCategory'

export default function DeliciusGridSpecialCategory({ data, nameCategory }) {
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(4)
  console.log('START', start)
  const numberOfImages = 4
  let maxEnd = 0
  while (maxEnd < data.length) {
    maxEnd += numberOfImages
  }

  // let goleft = () => {
  //   if (start == 0) {
  //     setStart(maxEnd - numberOfImages)
  //     setEnd(maxEnd)
  //   } else {
  //     setStart(start - numberOfImages)
  //     setEnd(start)
  //   }
  // }

  // let gorigth = () => {
  //   if (end >= data.length) {
  //     setStart(0)
  //     setEnd(numberOfImages)
  //   } else {
  //     setStart(end)
  //     setEnd(end + numberOfImages)
  //   }
  // }
  let gorigth = () => {
    let element = document.getElementById(`concat-trending`)
    sideScroll(element, 'right', 80, 300, 200)
    // spaceScroll.current += 300
    // console.log(element.scrollLeft)
    // element.scrollLeft = spaceScroll.current
  }
  let goleft = () => {
    let element = document.getElementById(`concat-trending`)
    console.log(element)
    sideScroll(element, 'left', 80, 300, 200)
    // spaceScroll.current -= 300
    // console.log(element.scrollLeft)
    // element.scrollLeft = spaceScroll.current
  }
  function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0
    const slideTimer = setInterval(function () {
      if (direction === 'left') {
        element.scrollLeft -= step
      } else {
        element.scrollLeft += step
      }
      scrollAmount += step
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer)
      }
    }, speed)
  }

  return (
    <div>
      <div className='w-full   text-2xl font-bold tracking-wider  text-center'>
        {nameCategory}
      </div>
      <div className='container flex'>
        {/* <div
        id='gridProductLg'
        className='w-full flex flex-row justify-between text-2xl font-bold tracking-wider '
      >
        <div className='mb-6 text-2xl font-semibold tracking-tighter md:text-4xl'>
          {nameCategory}
        </div>
        {data.length > 4 ? (
          <div className='flex gap-4 mb-6 md:gap-8'>
            <button className='px-2' onClick={(e) => goleft(e)}>
              {'<'}
            </button>
            <button className='px-2' onClick={(e) => gorigth(e)}>
              {'>'}
            </button>
          </div>
        ) : (
          ''
        )}
      </div> */}

        {/* <div className="mb-6 text-2xl font-semibold tracking-tighter md:text-4xl">{nameCategory}</div> */}
        {data.length > 3 && (
          <div className='hidden md:flex gap-8'>
            <button className='px-2 text-4xl' onClick={(e) => goleft(e)}>
              {'<'}
            </button>
          </div>
        )}

        <div
          id={`concat-trending`}
          className='flex flex-row gap-6 py-4 overflow-x-scroll no-scrollbar'
        >
          {data.map((product, index) => {
            return (
              <div className='col-span-2 w-full'>
                <DeliciousProductSpecialCategory
                  xl
                  rounded
                  overlay
                  key={index}
                  id={product._id}
                  imagenproducto={product.imagenproducto}
                  image={product.image}
                  name={product.nombreproducto}
                  price={product.precio}
                  codigoproducto={product.codigoproducto}
                  variations={product.variations}
                  stock={product.stock}
                />
              </div>
            )
          })}
        </div>
        {data.length > 3 && (
          <div className='hidden md:flex gap-8'>
            <button className='px-2 text-4xl' onClick={(e) => gorigth(e)}>
              {'>'}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
